<template>
  <div v-if="content?.content" ref="rootEl" class="duo-content">
    <HtmlText class="duo-content__content" :content="content.content" />
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { onMounted, ref } from 'vue'

import { onAppear } from '@/utils/motion'

import type { PropType } from 'vue'

interface DuoContent {
  content: string
}

defineProps({
  content: {
    type: Object as PropType<DuoContent>,
    required: true,
  },
})

const rootEl = ref<HTMLElement | null>(null)

onMounted(() => {
  if (!rootEl.value) {
    return
  }

  gsap.set(rootEl.value, { opacity: 0 })

  // Animate on appear
  onAppear(rootEl.value)
})
</script>

<style lang="scss" scoped>
$bp: m;

.duo-content {
  margin: 0;
  list-style-type: none;
  padding: $spacing * 1.5 col(1);

  @include mq($bp) {
    padding: $spacing * 2 col(1, var(--available-columns));
  }

  @include mq(xl) {
    padding: $spacing * 3 col(1, var(--available-columns)) $spacing * 3
      col(2, var(--available-columns));
  }
}
</style>
